(<template>
  <div :class="{
         'one-result--green': testPassed,
         'one-result--red': testFailed,
         'is-active': activeTest
       }"
       class="one-result"
       role="button"
       tabindex="0"
       @keyup.enter.space="goToRecording"
       @click="goToRecording">
    <p class="one-result__file-name">{{ titleString }}</p>
    <p class="one-result__file-date">{{ dateString }}</p>
    <button class="one-result__delete-btn"
            @click.stop.prevent="handleDelete"></button>
  </div>
</template>)

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';

  export default {
    props: {
      recording: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('TTTestVideoStore', ['activeRecording']),
      titleString() {
        if (!this.browser || !this.hardware) {
          const template = this.$gettext('#%{id} | Processing...');
          return this.$gettextInterpolate(template, {id: this.recordingID});
        }
        return `#${this.recordingID} | ${this.browser}, ${this.hardware}`;
      },
      activeTest() { return this.activeRecording == this.recordingID; },
      testPassed() { return this.recording.voiceWorks && this.recording.videoWorks; },
      testFailed() { return this.recording.voiceWorks === false || this.recording.videoWorks === false; },
      browser() { return this.recording.browser; },
      hardware() { return this.recording.device; },
      recordingID() { return this.recording.id; },
      dateString() {
        const rMoment = this.$moment(this.recording.date);
        return `${rMoment.year()} ${this.$helperData.getMonthNames(this)[rMoment.month()].slice(0, 3)} ${rMoment.format('DD')} - ${rMoment.format('HH:mm')}`;
      }
    },
    methods: {
      ...mapActions('TTTestVideoStore', ['getOneRecording']),
      ...mapMutations('TTTestVideoStore', ['startPageProgress', 'stopPageProgress', 'setOneRecording']),
      goToRecording() {
        if (this.activeRecording != this.recording.id) {
          this.setOneRecording({videoTest: this.recording});
        }
      },
      handleDelete() {
        this.$emit('delete', this.recording.id);
      }
    }
  };
</script>

<style scoped>
  .one-result {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px 10px;
    padding-right: 40px;
    background-color: #f4f5f7;
    cursor: pointer;
  }

  .one-result:focus {
    outline: var(--default-button-focus-outline);
  }

  .one-result.is-active {
    background-color: #d3d5de;
  }

  .one-result--green {
    background-color: #9de0b8;
  }

  .one-result--green.is-active {
    background-color: #61e496;
  }

  .one-result--red {
    background-color: #f57b9c;
  }

  .one-result--red.is-active {
    background-color: #f35a7b;
  }

  .one-result__file-name {
    display: block;
    width: 100%;
    font-weight: bold;
  }

  .one-result__delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 40px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
    background-position: 50% 50%;
    background-size: 13px auto;
    background-repeat: no-repeat;
  }

  .one-result__delete-btn:focus {
    outline: var(--default-button-focus-outline);
  }
</style>
