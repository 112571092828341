// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.one-result[data-v-35fc517e] {\n  position: relative;\n  display: block;\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 5px 10px;\n  padding-right: 40px;\n  background-color: #f4f5f7;\n  cursor: pointer;\n}\n.one-result[data-v-35fc517e]:focus {\n  outline: var(--default-button-focus-outline);\n}\n.one-result.is-active[data-v-35fc517e] {\n  background-color: #d3d5de;\n}\n.one-result--green[data-v-35fc517e] {\n  background-color: #9de0b8;\n}\n.one-result--green.is-active[data-v-35fc517e] {\n  background-color: #61e496;\n}\n.one-result--red[data-v-35fc517e] {\n  background-color: #f57b9c;\n}\n.one-result--red.is-active[data-v-35fc517e] {\n  background-color: #f35a7b;\n}\n.one-result__file-name[data-v-35fc517e] {\n  display: block;\n  width: 100%;\n  font-weight: bold;\n}\n.one-result__delete-btn[data-v-35fc517e] {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  display: block;\n  width: 40px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 13px auto;\n  background-repeat: no-repeat;\n}\n.one-result__delete-btn[data-v-35fc517e]:focus {\n  outline: var(--default-button-focus-outline);\n}\n", ""]);
// Exports
module.exports = exports;
